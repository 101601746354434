.sidebar {
    flex: 3;
    margin: 20px;
    padding-bottom: 30px;
    background-color: #fdfbfb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-block: 1px solid #a7a4a4;
    font-family: 'Varela Round', sans-serif;
    font-size: 12px;
    color: #222;
    font-weight: 600;
    line-height: 20px;
}

.sidebarImg {
    margin-top: 15px;
    width: 280px;
    height: 280px;
    object-fit: cover;
}

.sidebarItem > p {
    padding: 30px;
}

.sidebarList {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
}

.sidebarListItem {
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}

.sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebarIcon {
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
}