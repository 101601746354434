.singlePost {
    flex: 9;
}

.singlePostWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-right: 0;
}


.singlePostImg {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.singlePostTitle {
    text-align: center;
    margin: 10px;
    font-family: 'Lora', serif;
    font-size: 28px;
}

.singlePostTitleInput {
    margin: 10px;
    font-family: 'Lora', serif;
    font-size: 28px;
    border: none;
    color: gray;
    border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
    outline: none;
}

.singlePostEdit {
    float: right;
    font-size: 16px;
}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child {
    color: teal;
}

.singlePostIcon:last-child {
    color: tomato;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: #b39656;
}

.singlePostDescription {
    color: #666;
    font-size: 18px;
    line-height: 25px;
}
.singlePostDescriptionInput {
    color: #666;
    font-size: 18px;
    line-height: 25px;
    border: none;
}
.singlePostDescriptionInput:focus {
    outline: none;
}

.singlePostDescription::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
}

.singlePostButtonUpdate {
    width: 100px;
    border: none;
    background-color: teal;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}

.singlePostButtonCancel {
    width: 100px;
    border: none;
    background-color: grey;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 10px;
}
