.login {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("https://images.pexels.com/photos/768473/pexels-photo-768473.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500");
    background-size: cover;
}

.loginTitle {
    font-size: 50px;
}

.loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm > label {
    margin: 10px 0;
}

.loginInput {
    padding: 10px;
    background-color: white;
    border: none;
}

.loginButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.loginButton:disabled {
    cursor: not-allowed;
    background-color: rgb(248, 149, 149);
}

.loginRegisterButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: teal;
    cursor: pointer;
    color: white;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 10px;
}