.register {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("https://img.freepik.com/photos-gratuite/coquillages-plage-sable-libre-idee-mise-au-point-selective-pour-economiseur-ecran-publicite_166373-2943.jpg?w=1060&t=st=1664543559~exp=1664544159~hmac=7491f34927dc8aac1ebb614497263ec7eb43817d7c9c00b8b4231efe7615c76c");
    background-size: cover;
}

.registerTitle {
    font-size: 50px;
}

.registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.registerForm > label {
    margin: 10px 0;
}

.registerInput {
    padding: 10px;
    background-color: white;
    border: none;
}

.registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 10px;
}

.registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    color: white;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 10px;
}