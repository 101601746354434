.write {
    padding-top: 50px;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("https://images.pexels.com/photos/317355/pexels-photo-317355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
}

.writeImg {
    margin-left: 150px;
    width: 70vw;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}

.writeForm {
    position: relative;
}

.writeFormGroup {
    margin-left: 150px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
}

.writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(121, 118, 118);
    cursor: pointer;
}

.writeInput {
    font-size: 20px;
    border: none;
    padding: 20px;width: 70vw;
    background-color: transparent;
}

.writeInput {
    outline: none;
}

.writeText {
    font-style: 20px;
    height: 100vh;
}

.writeSubmit {
    position: absolute;
    top: 20px;
    right: 50px;
    color: white;
    background-color: teal;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}